import React from 'react';

import VirusIcon from '~/images/virus.svg';
import Container from '~/components/container';
import Infection from '~/components/infection';

const Footer = () => (
  <Container>
    <hr className="border-x-brown border-t-2 mt-16 mb-8" />
    <div className="flex items-start flex-col sm:flex-row">
      <img
        className="h-12 w-12 mr-4 mb-2 sm:h-16 sm:w-16 sm:mr-8 object-contain"
        src={VirusIcon}
        alt="Coronavirus"
      />
      <div>
        <div>
          We needed a place to start to bring structure to the new world around
          us. To find numbers in the fog of unknown. Our hope is this helps you
          too.
        </div>
        <div className="mt-4">
          If you have a suggested list,{' '}
          <a href="mailto:thequarantinist@gmail.com" className="underline">
            send it our way
          </a>
          .
        </div>
      </div>
    </div>
    <div className="mt-4 text-sm">
      Brought to you by the creators of{' '}
      <a href="https://parsnip.me" className="underline">
        Parsnip
      </a>
      <br />
      Designed by{' '}
      <a href="https://emilyhines.me" className="underline">
        Emily Hines
      </a>
    </div>
    <div className="mt-12" />
    <Infection />
  </Container>
);
export default Footer;
