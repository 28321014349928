import React from 'react';
import PropTypes from 'prop-types';

import Header from '~/components/header';
import Footer from '~/components/footer';
import Container from '~/components/container';
import '~/styles/layout.css';

const Layout = ({ children }) => (
  <div className="flex flex-col h-full">
    <Header />
    <main className="flex-1">
      <Container>{children}</Container>
    </main>
    <Footer />
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
