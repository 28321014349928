import React from 'react';
import styled from '@emotion/styled';
import tw from 'twin.macro';

import Container from '~/components/container';
import VirusIcon from '~/images/virus.svg';

const HeaderTitle = styled.h1`
  ${tw`text-3xl sm:text-5xl font-bold`}
  font-family: 'Poppins', sans-serif;
`;

const Header = () => (
  <Container className="py-6">
    <HeaderTitle>Coronavirus Lists</HeaderTitle>
    <div className="flex items-start flex-col sm:flex-row sm:items-center">
      <img
        className="h-12 w-12 mr-4 mb-2 sm:h-16 sm:w-16 object-contain"
        src={VirusIcon}
        alt="Coronavirus"
      />
      <h2 className="max-w-lg px-4 text-xl sm:text-2xl">
        All The Lists You Need to Survive The Coronavirus Pandemic – COVID-19
      </h2>
    </div>
    <hr className="border-x-brown border-t-2 mt-8" />
  </Container>
);

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
