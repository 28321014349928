import React from 'react';
import styled from '@emotion/styled';

import VirusBackground from '~/images/virus_bg.png';

const Grid = styled.div`
  opacity: 0.3;

  height: 40rem;
  background-image: url(${VirusBackground});
  background-repeat: space;
`;

const Infection = () => <Grid />;

export default Infection;
